import "./About.css";
const About = () => {
    return(
        <div className="about-body">
            <h1 className="about-header1">About</h1>
            <p className="about-p1">
            WoW DF Mythic+ Team "DreadPlus" Web Project
            <br/><br/>
            </p>
        </div>
    )
}
export default About;